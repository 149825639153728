import { ax } from '@/main.js'

const state = {
  accounts: [],
  blueprintTestAccounts: [],
  countryGeoTargets: [],
  nonManagerAccounts: [],
  subMccAccounts: [],
}

const mutations = {
  setAccounts (state, list) {
    state.accounts = list
  },
  setBlueprintTestAccounts (state, list) {
    state.blueprintTestAccounts = list
  },
  setCountryGeoTargets (state, list) {
    state.countryGeoTargets = list
  },
  setNonManagerAccounts (state, list) {
    state.nonManagerAccounts = list
  },
  setSubMccAccounts (state, list) {
    state.subMccAccounts = list
  },
}

// no error handling: let caller handle with try/catch outside store
const actions = {
  async loadAccounts ({ commit }) {
    const resp = await ax.get('/a/google/accounts?xsort=name&xfields=account_id,name,can_manage_clients&xper_page=5000')
    commit('setAccounts', resp.data.data)

    var filteredAccs = resp.data.data.filter(el => el.name.toLowerCase().includes('blueprint') || el.name.toLowerCase().includes('test'))
    commit('setBlueprintTestAccounts', filteredAccs)

    var subMccAccs = resp.data.data.filter(el => el.can_manage_clients)
    commit('setSubMccAccounts', subMccAccs)

    var nonMgrAccs = resp.data.data.filter(el => !el.can_manage_clients)
    commit('setNonManagerAccounts', nonMgrAccs)
  },
  async loadCountryGeoTargets ({ commit }) {
    const resp = await ax.get('/a/google/country-geo-targets?xfields=name,criterion_id&xper_page=5000')
    commit('setCountryGeoTargets', resp.data.data)
  }

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}