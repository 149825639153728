import { ax } from '@/main.js'

const state = {
  approvalStati: [],
  keywordTypes: [
    { 'name': 'Brand', 'icon': 'copyright'},
    { 'name': 'Competitor', 'icon': 'sports_kabaddi'},
    { 'name': 'Performance', 'icon': 'speed'},
  ],
}

const mutations = {
  setApprovalStati (state, list) {
    state.approvalStati = list
  },
}

// no error handling: let caller handle with try/catch outside store
const actions = {
  async loadApprovalStati ({ commit }) {
    const resp = await ax.get('/a/nkw/approval-stati')
    commit('setApprovalStati', resp.data.data)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}