import Vue from 'vue'
import Vuex from 'vuex'
import bing from '@/store/modules/bing.js'
import common from '@/store/modules/common.js'
import core from '@/store/modules/core.js'
import google from '@/store/modules/google.js'
import nkw from '@/store/modules/nkw.js'
import system from '@/store/modules/system.js'

Vue.use(Vuex)

// store setup based on: https://github.com/UAVCAN/yukon/blob/master/src/yukon/frontend/src/store/index.js

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    bing,
    common,
    core,
    google,
    nkw,
    system,
  },
  strict: debug,
  plugins: []
})
