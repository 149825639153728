import Vue from 'vue'
import VueRouter from 'vue-router'
import auth from '@/auth'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/login',
      name: 'Login',
      component: () => import('@/GoogleLogin'),
    },
    {
      path: '/',
      component: () => import('@/AppFrame'),
      meta: { requiresAuth: true },
      children: [
        {
          path: '/home',
          name: 'Home',
          component: () => import('@/views/Home'),
        },
        {
          path: '/accounts',
          name: 'Accounts',
          component: () => import('@/views/Accounts'),
        },
        {
          path: '/accounts/:accountID',
          props: (route) => {
            return {
              id: parseInt(route.params.accountID),
            }
          },
          name: 'Account',
          component: () => import('@/views/AccountDetail'),
        },
        {
          path: '/ad-customizer',
          name: 'Ad customizer',
          component: () => import('@/views/AdCustomizer'),
        },
        {
          path: '/bing-rules',
          name: 'Bing rules',
          component: () => import('@/views/BingRules'),
        },
        {
          path: '/ad-customizer/aggregated-feed-items/:aggFeedItemID',
          props: (route) => {
            return {
              id: parseInt(route.params.aggFeedItemID),
            }
          },
          name: 'Aggregated ad feed',
          component: () => import('@/components/adCustomizer/AggregatedFeedItemDetail'),
        },
        {
          path: '/assignments',
          name: 'Assignments',
          component: () => import('@/views/Assignments'),
        },
        {
          path: '/assignments/:assignmentID',
          props: (route) => {
            return {
              id: parseInt(route.params.assignmentID),
            }
          },
          name: 'Assignment',
          component: () => import('@/views/AssignmentDetail'),
        },
        {
          path: '/cloning-tool',
          name: 'Cloning tool',
          component: () => import('@/views/CloningTool'),
        },
        {
          path: '/content-creator',
          name: 'Content creator',
          component: () => import('@/views/ContentCreator'),
        },
        {
          path: '/discounts',
          name: 'Discounts',
          component: () => import('@/views/Discounts'),
        },
        {
          path: '/geos',
          name: 'Geos',
          component: () => import('@/views/Geos'),
        },
        {
          path: '/geos/countries/:countryID',
          props: (route) => {
            return {
              id: parseInt(route.params.countryID),
            }
          },
          name: 'Country',
          component: () => import('@/views/CountryDetail'),
        },
        {
          path: '/geos/regions/:regionID',
          props: (route) => {
            return {
              id: parseInt(route.params.regionID),
            }
          },
          name: 'Region',
          component: () => import('@/views/RegionDetail'),
        },
        {
          path: '/keyword-collector',
          name: 'Keyword collector',
          component: () => import('@/views/KeywordCollector'),
        },
        {
          path: '/keyword-collector/blacklist/:itemID',
          props: (route) => {
            return {
              id: parseInt(route.params.itemID),
            }
          },
          name: 'Keyword collector blacklist detail',
          component: () => import('@/components/keywordCollector/BlacklistDetail'),
        },
        {
          path: '/keyword-relocation',
          name: 'Keyword relocation',
          component: () => import('@/views/KeywordRelocation'),
        },
        {
          path: '/negative-keywords',
          name: 'Negative keywords',
          component: () => import('@/views/NegativeKeywords'),
        },
        {
          path: '/negative-keywords/:nkwID',
          props: (route) => {
            return {
              id: parseInt(route.params.nkwID),
            }
          },
          name: 'Negative keyword',
          component: () => import('@/views/NegativeKeywordDetail'),
        },
        {
          path: '/negative-keywords/dwh-whitelist/:itemID',
          props: (route) => {
            return {
              id: parseInt(route.params.itemID),
            }
          },
          name: 'Negative keywords DWH whitelist detail',
          component: () => import('@/components/negativeKeywords/NkwDwhWhitelistDetail'),
        },
        {
          path: '/negative-keyword-lists',
          name: 'Negative keyword lists',
          component: () => import('@/views/NegativeKwLists'),
        },
        {
          path: '/notifications',
          name: 'Notifications',
          component: () => import('@/views/Notifications'),
        },
        {
          path: '/providers',
          name: 'Providers',
          component: () => import('@/views/Providers'),
        },
        {
          path: '/providers/:providerID',
          props: (route) => {
            return {
              id: parseInt(route.params.providerID),
            }
          },
          name: 'Provider',
          component: () => import('@/views/ProviderDetail'),
        },
        {
          path: '/sessions',
          name: 'Sessions',
          component: () => import('@/views/Sessions'),
        },
        {
          path: '/users',
          name: 'Users',
          component: () => import('@/views/Users'),
        },
        {
          path: '/users/:userID',
          props: (route) => {
            return {
              id: parseInt(route.params.userID),
            }
          },
          name: 'User',
          component: () => import('@/views/UserDetail'),
        },
        {
          path: '/verticals',
          name: 'Verticals',
          component: () => import('@/views/Verticals'),
        },
        {
          path: '/verticals/:verticalID',
          props: (route) => {
            return {
              id: parseInt(route.params.verticalID),
            }
          },
          name: 'Vertical',
          component: () => import('@/views/VerticalDetail'),
        },
      ]
    }
  ],
})

router.beforeEach((to, from, next) => {
  // nav guard: redirect to /login if the page requires auth and the user is not authed
  if (to.matched.some(r => r.meta.requiresAuth) && !auth.user.authenticated) {
    var destPath = '/login'
    if (to.path.length > 1) {
      destPath += '?to=' + to.path
    }
    router.push(destPath)
    return
  }

  next()
})

router.afterEach((to, from) => {
  Vue.nextTick(() => {
    // set the page title to be the route name followed by the appName from main.js
    document.title = to.name + ' | ' + router.app.$appName
  })
})

export default router
