import { ax } from '@/main.js'

const state = {
  activeProviders: [],
  activeVerticals: [],
  adNetworks: [],
  discountApprovalStati: [],
  domainTypes: [],
  keywordRelocationStati: [],
  matchTypes: [],
  seaTeams: [],
  verticals: [],
}

const mutations = {
  setActiveProviders (state, list) {
    state.activeProviders = list
  },
  setActiveVerticals (state, list) {
    state.activeVerticals = list
  },
  setDiscountApprovalStati (state, list) {
    state.discountApprovalStati = list
  },
  setAdNetworks (state, list) {
    state.adNetworks = list
  },
  setDomainTypes (state, list) {
    state.domainTypes = list
  },
  setKeywordRelocationStati (state, list) {
    state.keywordRelocationStati = list
  },
  setMatchTypes (state, list) {
    state.matchTypes = list
  },
  setSeaTeams (state, list) {
    state.seaTeams = list
  },
  setVerticals (state, list) {
    state.verticals = list
  }
}

// no error handling: let caller handle with try/catch outside store
const actions = {
  async loadActiveProviders ({ commit }) {
    const resp = await ax.get('/a/core/providers?is_active=true&xfields=id,name&xsort=name&xper_page=5000')
    commit('setActiveProviders', resp.data.data)    
  },
  async loadAdNetworks ({ commit }) {
    const resp = await ax.get('/a/core/ad-networks?vals=Google,Bing')
    commit('setAdNetworks', resp.data.data)    
  },
  async loadDiscountApprovalStati ({ commit }) {
    const resp = await ax.get('/a/core/discount-approval-stati')
    commit('setDiscountApprovalStati', resp.data.data)    
  },
  async loadDomainTypes ({ commit }) {
    const resp = await ax.get('/a/core/domain-types?xfields=id,name&xsort=name&xper_page=5000')
    commit('setDomainTypes', resp.data.data)    
  },
  async loadKeywordRelocationStati ({ commit }) {
    const resp = await ax.get('/a/core/keyword-relocation-stati')
    commit('setKeywordRelocationStati', resp.data.data)
  },
  async loadMatchTypes ({ commit }) {
    const resp = await ax.get('/a/core/match-types')
    commit('setMatchTypes', resp.data.data)
  },
  async loadSeaTeams ({ commit }) {
    const resp = await ax.get('/a/core/sea-teams')
    commit('setSeaTeams', resp.data.data)
  },
  async loadVerticals ({ commit }) {
    const resp = await ax.get('/a/core/verticals?xfields=id,name,is_active,is_speedboat&xsort=name&xper_page=5000')
    commit('setVerticals', resp.data.data)    

    var filteredVerticals = resp.data.data.filter(el => el.is_active || el.is_speedboat || el.id == 0)
    commit('setActiveVerticals', filteredVerticals)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}