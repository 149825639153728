import { ax } from '@/main.js'

const state = {
  activeCountries: [],
  activeCountriesWithAll: [], // where "All" replaces "None" for value 0
  activeLanguages: [],
  countries: [],
  languages: [],
  regions: [],
}

const mutations = {
  setActiveCountries (state, list) {
    state.activeCountries = list
  },
  setActiveCountriesWithAll (state, list) {
    // replace None with All
    list.map(el => {
      if (el.name === 'None') {
        el.name = 'All'
      }
      return el
    })
    // sort so that All moves up
    list.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
   
    state.activeCountriesWithAll = list
  },
  setActiveLanguages (state, list) {
    state.activeLanguages = list
  },
  setCountries (state, list) {
    state.countries = list
  },
  setLanguages (state, list) {
    state.languages = list
  },
  setRegions (state, list) {
    state.regions = list
  }
}

// no error handling: let caller handle with try/catch outside store
const actions = {
  async loadCountries ({ commit }) {
    const resp = await ax.get('/a/common/countries?xfields=id,name,iso2,is_active&xsort=name&xper_page=5000')
    commit('setCountries', resp.data.data)

    var filteredCountries = resp.data.data.filter(el => el.is_active)
    commit('setActiveCountries', filteredCountries)
    commit('setActiveCountriesWithAll', filteredCountries)
  },
  async loadLanguages ({ commit }) {
    const resp = await ax.get('/a/common/languages?xfields=id,name,iso2,is_active&xsort=name&xper_page=5000')
    commit('setLanguages', resp.data.data)

    var filteredLanguages = resp.data.data.filter(el => el.is_active)
    commit('setActiveLanguages', filteredLanguages)
  },
  async loadRegions ({ commit }) {
    const resp = await ax.get('/a/common/regions?xfields=id,name&xsort=name&xper_page=5000')
    commit('setRegions', resp.data.data)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}