import { ax } from '@/main.js'

const state = {
    accounts: [],
  }
  
  const mutations = {
    setAccounts (state, list) {
      state.accounts = list
    }
  }
  
  // no error handling: let caller handle with try/catch outside store
  const actions = {
    async loadAccounts ({ commit }) {
      const resp = await ax.get('/a/bing/accounts?xsort=name&xfields=account_id,name&xper_page=5000')
      commit('setAccounts', resp.data.data)
    },
  }
  
  export default {
    namespaced: true,
    state,
    mutations,
    actions
  }