import { ax } from '@/main.js'

const state = {
  apiError: null,
  permissions: [],
  activeUsers: []
}

const mutations = {
  setAPIError (state, apiErr) {
    state.apiError = apiErr
  },
  setPermissions (state, list) {
    state.permissions = list
  },
  setActiveUsers (state, list) {
    state.activeUsers = list
  }
}

// no error handling: let caller handle with try/catch outside store
const actions = {
  async loadPermissions ({ commit }) {
    const resp = await ax.get('/a/system/permissions?xsort=name&xfields=id,name')
    commit('setPermissions', resp.data.data)
  },
  async loadActiveUsers ({ commit }) {
    const resp = await ax.get('/a/system/users?xsort=name&xfields=id,name&is_active=true&xper_page=1000')
    commit('setActiveUsers', resp.data.data)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}