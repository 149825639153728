export default {

  // User object will let us check authentication status
  user: {
    authenticated: false,
    name: '',
    initials: '',
    id: 0,
    permissions: [],
  },

  hasPermission (perm) {
    return this.user.permissions.includes(perm)
  },

  logout () {
    localStorage.removeItem('id_token')
    this.user.authenticated = false
    this.user.name = ''
    this.user.initials = ''
    this.user.id = 0
    this.user.permissions = []
  },
}
