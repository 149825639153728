import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// vuetify as component library
import vuetify from '@/plugins/vuetify'
import 'material-design-icons-iconfont/dist/material-design-icons.css'

// luxon for date/time
import { DateTime } from 'luxon'

// axios for API requests
import axios from 'axios'

var apiURL = 'https://adtech-api.wandoso.com'
if (process.env.NODE_ENV === 'development') {
  apiURL = 'http://localhost:8010'
}

const axVar = axios.create({
  baseURL: apiURL,
  // timeout: 1000,
  headers: { 'Content-Type': 'application/json' },
})

// exporting so that Vuex also has access to this configured instance of axios (see store/)
export const ax = axVar

Vue.prototype.$dateTime = DateTime
Vue.prototype.$http = axVar
Vue.prototype.$appName = 'AdTech UI'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
